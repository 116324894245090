.card {
    position: relative;
    perspective: 500px;
    height:240px;
    width:210px;
  }
  .front, .back {
    position: absolute;
    transition: transform 1s;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    background-color: #F5EDDC;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  .back { 
    transform: rotateY(180deg); 
  }
  .card:hover .front{ transform: rotateY(180deg); }
  .card:hover .back { transform: rotateY(360deg); }

  @media only screen and (max-width: 600px) {
    .card {
      width:310px;
      height:370px;
    }

  }